









































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {required} from '@/utils/validationRules';
import ICompanySettingsForm from '@/types/ICompanySettingsForm';
import CompanyService from '@/services/CompanyService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'CompanySettingsForm'})
export default class CompanySettingsForm extends Vue {
  loading: boolean = false;
  form: ICompanySettingsForm = {
    accessKey: '',
    authToken: '',
    clientSecret: '',
    developerId: '',
    sellerId: '',
    reportDate: '',
    defaultCurrency: ''
  };
  rules = {
    accessKey: [required],
    authToken: [required],
    clientSecret: [required],
    developerId: [required],
    sellerId: [required],
    reportDate: [required],
  };

  @Prop()
  data!: ICompanySettingsForm;

  @Prop()
  fetchData!: Function;

  @Watch('data')
  onDataChange(newVal: ICompanySettingsForm) {
    if (newVal) {
      this.form = {...newVal};
    }
  }

  navigate(name: string) {
    this.$router.push({name: name});
  }

  async save() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        // TODO: save data
        await CompanyService.updateCompanySettings(this.form);
        await this.fetchData();
        notify(NotificationTypes.success, this.$t('SYSTEM_SAVED'), this.$t('SYSTEM_CHANGES_HAVE_BEEN_SAVED'));
        this.$emit('fetchData');
      } catch (e) {
        resolveError(e, 'save');
      } finally {
        this.loading = false;
      }
    }
  }
}
