import IPaymentMethod from '@/types/IPaymentMethod';

export default class PaymentMethodsFactory {
  static toPaymentsMethod(data: any): IPaymentMethod {
    return {
      cardType: data.brand,
      endsWith: data.last4,
      expiresIn: `${data.expMonth}/${data.expYear}`,
      id: data.id,
      isDefault: data.defaultMethod
    }
  }
}
