


























































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ICompanyForm from '@/types/ICompanyForm';
import {required} from '@/utils/validationRules';
import ICountry from '@/types/ICountry';
import AuthService from '@/services/AuthService';
import CompanyService from '@/services/CompanyService';
import {NotificationTypes, notify, resolveError} from '@/utils/notifications';

@Component({name: 'CompanyForm'})
export default class CompanyForm extends Vue {
  countries: ICountry[] = [];
  loading: boolean = false;

  @Prop()
  data!: ICompanyForm;

  @Prop()
  fetchData!: Function;

  form: ICompanyForm = {
    addressId: 0,
    companyName: '',
    nip: '',
    address: '',
    city: '',
    countryId: 0,
    postalCode: '',
  };
  rules = {
    companyName: [required],
    address: [required],
    city: [required],
    countryId: [required],
    postalCode: [required],
  };

  @Watch('data')
  onDataChange(newVal: ICompanyForm) {
    if (newVal) {
      this.form = {...newVal};
    }
  }

  mounted() {
    this.fetchCountries();
  }

  async fetchCountries() {
    try {
      this.countries = await AuthService.getRegisterForm();
    } catch (e) {
      resolveError(e, 'fetch_countries');
    }
  }

  async save() {
    // @ts-ignore
    if (this.$refs.form.validate()) {
      this.loading = true;
      try {
        await CompanyService.updateCompanyAddress(this.form);
        await this.fetchData();
        notify(NotificationTypes.success, this.$t('SYSTEM_SAVED'), this.$t('SYSTEM_CHANGES_HAVE_BEEN_SAVED'));
      } catch (e) {
        resolveError(e, 'save');
      } finally {
        this.loading = false;
      }
    }
  }
}
