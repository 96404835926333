import axios from 'axios';

export default class PaymentsRepository {
  static getPayments(data: object): Promise<any> {
    return axios.post('stripe/payment', data);
  }

  static downloadInvoice(id: number): Promise<any> {
    return axios.get(`stripe/invoice/${id}`, {responseType: 'arraybuffer'});
  }

  static getPlans(): Promise<any> {
    return axios.get('stripe/plans');
  }

  static checkoutSubscription(data: object): Promise<any> {
    return axios.post('stripe/payment/subscription', data);
  }

  static getActiveSubscription(): Promise<any> {
    return axios.get('stripe/subscription');
  }

  static cancelSubscription(): Promise<any> {
    return axios.delete('stripe/subscription');
  }

  static restoreSubscription(): Promise<any> {
    return axios.post('stripe/subscription/restore');
  }

  static getPaymentMethods(): Promise<any> {
    return axios.get('stripe/payment-methods')
  }

  static addPaymentMethods(api: any): Promise<any> {
    return axios.post('stripe/payment-method', api)
  }

  static setPaymentMethodAsDefault(id: number): Promise<any> {
    return axios.post(`stripe/payment-method/default/${id}`)
  }

  static removePaymentMethod(id: number): Promise<any> {
    return axios.delete(`stripe/payment-method/${id}`)
  }
}
