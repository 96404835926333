import {IPagination} from '@/types/IPagination';
import PaymentsRepository from '@/repositories/PaymentsRepository';
import UserFactory from '@/factories/UserFactory';
import PaymentMethodsFactory from '@/factories/PaymentMethodsFactory';
import IPaymentMethod from '@/types/IPaymentMethod';

export default class PaymentService {

    static async getPayments(params: IPagination): Promise<any> {
        const {data} = await PaymentsRepository.getPayments({
            page: params.page,
            size: params.size,
        });
        return UserFactory.toPaymentsList(data);
    }

    static downloadInvoice(id: number): Promise<any> {
        return PaymentsRepository.downloadInvoice(id);
    }

    static async fetchPlans() {
        const {data} = await PaymentsRepository.getPlans();
        return data;
    }

    static attachStripeScript() {
        if (!document.getElementById('stripe')) {
            const stripeScript = document.createElement('script');
            stripeScript.setAttribute('id', 'stripe');
            stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
            document.head.appendChild(stripeScript);
        }
    }

    static checkoutSubscription(planPeriodId: string): Promise<void> {
        return PaymentsRepository.checkoutSubscription({planPeriodId});
    }

    static async getActiveSubscription(): Promise<void> {
        const {data} = await PaymentsRepository.getActiveSubscription();
        return data;
    }

    static async cancelSubscription(): Promise<void> {
        return await PaymentsRepository.cancelSubscription();
    }

    static async restoreSubscription(): Promise<void> {
        return await PaymentsRepository.restoreSubscription();
    }

    static async getPaymentMethods(): Promise<IPaymentMethod[]> {
        const {data} = await PaymentsRepository.getPaymentMethods()

        return data.map((item: any) => PaymentMethodsFactory.toPaymentsMethod(item))
    }

    static async addPaymentMethodAsDefault(api: any): Promise<void> {
        return PaymentsRepository.addPaymentMethods(api)
    }

    static async setPaymentMethodAsDefault(id: number): Promise<void> {
        return PaymentsRepository.setPaymentMethodAsDefault(id)
    }

    static async removePaymentMethod(id: number): Promise<void> {
        return PaymentsRepository.removePaymentMethod(id)
    }
}
