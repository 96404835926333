


















































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import CreateOrEditCompanyUser from '@/components/Company/CreateOrEditCompanyUser.vue';
import {IUsersList} from '@/types/IUsersList';
import CompanyService from '@/services/CompanyService';
import {resolveError} from '@/utils/notifications';
import IUser from '@/types/IUser';
import UserFactory from '@/factories/UserFactory';
import {IUserEditForm} from '@/types/IUserEditForm';
import {IDataTablePagination, IPagination} from '@/types/IPagination';
import {isPermission} from '@/utils/perrmissions';

@Component({
  name: 'CompanyUsers',
  components: {
    CreateOrEditCompanyUser,
  },
})
export default class CompanyUsers extends Vue {
  createUser: IUserEditForm = UserFactory.toCreateUser({});
  roles: string[] = [];

  @Prop()
  data!: IUsersList[];

  @Prop()
  loading!: boolean;

  @Prop()
  params!: IPagination;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_FIRST_NAME',
      value: 'firstName',
    },
    {
      text: 'SYSTEM_LAST_NAME',
      value: 'lastName',
    },
    {
      text: 'SYSTEM_EMAIL',
      value: 'mail',
    },
    {
      text: 'SYSTEM_IS_MAIN',
      value: 'main',
    },
    {
      text: 'SYSTEM_ROLE',
      value: 'role',
    },
    {
      text: 'SYSTEM_ACTIONS',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  created() {
    this.getUserRoles();
  }

  async deleteCompanyUser(item: IUser) {
    item.loading = true;
    try {
      await CompanyService.deleteCompanyUser(item.id);
      this.update();
      item.menu = false;
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      item.loading = false;
    }
  }

  async getUserRoles() {
    try {
      const {data} = await CompanyService.getUserRoles();
      this.roles = data.roles;
    } catch (e) {
      resolveError(e, 'fetch_data');
    }
  }

  update() {
    this.$emit('update');
  }

  isPermission(permissions: string[]): boolean {
    return isPermission(permissions);
  }
};
