



































'use strict';

import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import CreateOrEditCompanyUser from '@/components/Company/CreateOrEditCompanyUser.vue';
import IPayment from '@/types/IPayment.ts';
import {resolveError} from '@/utils/notifications';
import {IDataTablePagination, IPagination} from '@/types/IPagination';
import PaymentService from '@/services/PaymentService';

@Component({
  name: 'PaymentHistory',
  components: {
    CreateOrEditCompanyUser,
  },
})
export default class PaymentHistory extends Vue {

  @Prop()
  data!: IPayment[];

  @Prop()
  loading!: boolean;

  @Prop()
  params!: IPagination;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_REALIZED_AT',
      value: 'realizedAt',
    },
    {
      text: 'SYSTEM_TITLE',
      value: 'title',
    },
    {
      text: 'SYSTEM_PRICE',
      value: 'priceIncludedTax',
    },
    {
      text: 'SYSTEM_ACTIONS',
      value: 'actions',
      align: 'end',
      sortable: false,
    },
  ];

  @Emit('paginationChanged')
  paginationChanged(pagination: IDataTablePagination) {
    return pagination;
  }

  async downloadDocument(item: IPayment) {
    item.loading = true;
    try {
      const result = await PaymentService.downloadInvoice(item.paymentId);
      const blob = new Blob([result.data], {type: result.headers['content-type']});
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');

      link.href = url;
      link.download = `invoice_sellcon_${item.realizedAt.substr(0, 10)}.pdf`;

      document.body.appendChild(link);

      link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          }),
      );

      document.body.removeChild(link);
      item.loading = false;
    } catch (e) {
      resolveError(e, 'download');
      item.loading = false;
    }
  }
};
