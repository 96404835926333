var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-3 pr-3"},[_c('div',{staticClass:"d-flex justify-space-between pb-3"},[_c('h2',[_vm._v(_vm._s(_vm.$t('SYSTEM_COMPANY_USERS')))]),(_vm.isPermission(['company.user_create']))?_c('CreateOrEditCompanyUser',{attrs:{"create":true,"data":_vm.createUser,"roles":_vm.roles},on:{"update":_vm.update}}):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"footer-props":{'items-per-page-options':[10, 30, 50, 100]},"headers":_vm.headers.map(function (item) { return (Object.assign({}, item, {text: _vm.$t(item.text)})); }),"items":_vm.data.content,"items-per-page":_vm.params.size,"loading":_vm.loading,"server-items-length":_vm.params.totalElements,"disable-sort":""},on:{"pagination":_vm.paginationChanged},scopedSlots:_vm._u([{key:"item.main",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.main ? 'success' : 'default'}},[_vm._v(" "+_vm._s(item.main ? _vm.$t('SYSTEM_YES') : _vm.$t('SYSTEM_NO'))+" ")])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.role))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isPermission(['company.user_update']))?_c('CreateOrEditCompanyUser',{attrs:{"create":false,"data":item,"roles":_vm.roles},on:{"update":_vm.update}}):_vm._e(),(_vm.isPermission(['company.user_delete']))?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isPermission(['company.user_delete']))?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_ARE_YOU_SURE_TO_DELETE?'))+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"disabled":item.loading,"loading":item.loading,"color":"red","dark":"","small":""},on:{"click":function($event){return _vm.deleteCompanyUser(item)}}},[_vm._v(" "+_vm._s(_vm.$t('SYSTEM_YES'))+" ")])],1)],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }