


















import {Component, Vue} from 'vue-property-decorator';
import {camelCaseToUnderscoreCase} from '@/utils/universalUtils';

@Component({
  name: 'PageWrap'
})
export default class PageWrap extends Vue {
  get translationPrefixByRoute() {
    if (this.$route.name) {
      return camelCaseToUnderscoreCase(this.$route.name).toUpperCase()
    }
  }
}
