import {IPagination} from '@/types/IPagination';

export default class DataTableFactory {
  static toPagination(data: any = {}): IPagination {
    return {
      page: data.page || 1,
      size: data.size || 10,
      totalElements: data.totalElements || 0,
      sortBy: data.sortBy || undefined,
      sortDirection: data.sortDirection || 'ASC',
      itemsPerPage: data.itemsPerPage || undefined,
    };
  }
}
